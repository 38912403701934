import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../layouts/secondary-landing";
import SEO from "../components/seo/seo";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import HeroChevron from "../components/hero/hero-chevron";
import Icon from "../components/custom-widgets/icon";
import VideoModal from "../components/video-modal/video-modal";
import VimeoVideoFrame from "../components/custom-widgets/vimeo-video-frame";

import getHeroImgVariables from "../helpers/getHeroImgVariables";
import BuiltLogo from "../assets/built-logo.svg";

const BuiltConstructionLoanManagement = () => {
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(relativePath: { eq: "hero/built-construction/hero-built-101123-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/built-construction/hero-built-101123-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/built-construction/hero-built-101123-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/built-construction/hero-built-101123-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/built-construction/hero-built-101123-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/built-construction/hero-built-101123-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/built-construction/hero-built-101123-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
      builtConstructionStep1: file(relativePath: { eq: "built-construction-step-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 350)
        }
      }
      builtConstructionStep2: file(relativePath: { eq: "built-construction-step-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 350)
        }
      }
      builtConstructionStep3: file(relativePath: { eq: "built-construction-step-3.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 350)
        }
      }
      builtConstructionStep4: file(relativePath: { eq: "built-construction-step-4.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 350)
        }
      }
      builtConstructionStep5: file(relativePath: { eq: "built-construction-step-5.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 350)
        }
      }
    }
  `);

  const [showBuiltVideo, setShowBuiltVideo] = useState(false);
  const handleCloseBuiltVideo = () => setShowBuiltVideo(false);
  const handleShowBuiltVideo = (e) => {
    e.preventDefault();
    setShowBuiltVideo(true);
  };

  const heroChevronData = {
    id: "built-construction-hero",
    ...getHeroImgVariables(imgData),
    altText: "Engineer, architect, and contractor on the construction site with a crane in the background.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "We've Partnered With Built for Better Building"
          }
        }
      ]
    }
  };

  const title = "Built Management",
    description =
      "Learn how WaFd Bank partners with Built software to manage your construction loan that allows you to monitor your construction project online in real time.";

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/built-construction-loan-management"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-built-101123.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      active: true,
      title: "Built Management"
    }
  ];

  const builtConstuctionSteps = {
    steps: [
      {
        text: "Lender activates loan in Built.",
        iconSrc: "../images/icons/built-bank-icon.svg",
        iconAltText: "Bank Icon",
        imageSrc: imgData.builtConstructionStep1.childImageSharp.gatsbyImageData,
        imageAltText: "Step 1 in Built Construction Project - Lender activates loan in Built"
      },
      {
        text: "Builder/Borrower submits a draw request.",
        iconSrc: "../images/icons/built-money-icon.svg",
        iconAltText: "Money Icon",
        imageSrc: imgData.builtConstructionStep2.childImageSharp.gatsbyImageData,
        imageAltText: "Step 2 in Built Construction Project - Builder/Borrower submits a draw request."
      },
      {
        text: "Inspection requested. Inspector conducts progress inspection.",
        iconSrc: "../images/icons/built-camera-icon.svg",
        iconAltText: "Camera Icon",
        imageSrc: imgData.builtConstructionStep3.childImageSharp.gatsbyImageData,
        imageAltText:
          "Step 1 in Built Construction Project - Inspection requested. Inspector conducts progress inspection"
      },
      {
        text: "Lender reviews the draw request, approves, and disburses funds.",
        iconSrc: "../images/icons/built-clipboard-money-icon.svg",
        iconAltText: "Clipboard Money Icon",
        imageSrc: imgData.builtConstructionStep4.childImageSharp.gatsbyImageData,
        imageAltText:
          "Step 1 in Built Construction Project - Lender reviews the draw request, approves, and disburses funds"
      },
      {
        text: "Builder/Borrower receives notification of disbursed funds approval.",
        iconSrc: "../images/icons/built-email-money-icon.svg",
        iconAltText: "Email Money Icon",
        imageSrc: imgData.builtConstructionStep5.childImageSharp.gatsbyImageData,
        imageAltText:
          "Step 1 in Built Construction Project - Builder/Borrower receives notification of disbursed funds approval"
      }
    ]
  };

  const videoDataNeighbors = {
    title: "Neighbors helping neighbors video",
    vimeoId: "692348121",
    minHeight: "300px",
    class: "m-auto iframe w-100 border-radius-12"
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />

      <Breadcrumb data={breadcrumbData} />
      <VideoModal
        show={showBuiltVideo}
        handleClose={handleCloseBuiltVideo}
        {...{
          id: "built-navigation-video-modal",
          VimeoID: "692431185",
          Title: "Built - WaFd - Owner Request Draw"
        }}
      />
      <section className="border-bottom border-success border-bottom border-6">
        <div className="container">
          <h1>
            Built is powering smarter construction finance. See how we've streamlined communication, draws and
            inspections.
          </h1>
          <div className="row justify-content-between mt-5">
            <div className="col-md-5">
              <h4>
                Built is dedicated to enhancing the borrower's construction draw experience. Built provides customers
                with features like: streamlined draw request process, real-time inspection reports, and faster time to
                funding. The cloud-based platform provides a centralized location to manage all loan activity creating
                transparency and efficiency for all parties involved. Managing your draw inspection and disbursement
                requests has never been easier.
              </h4>
            </div>
            <div className="col-md-7 col-lg-6">
              <VimeoVideoFrame {...videoDataNeighbors} />
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row justify-content-between">
          <div className="col-md-5">
            <div className="text-success h1">How Built Works</div>
            <h5 className="text-green-60">Monitor your construction project online in real time.</h5>
            <p>
              Our software brings the construction administration process online{" "}
              <strong>making requesting funds easier</strong> than ever before. By connecting all key stakeholders Built
              will <strong>speed up your time to funding</strong> and provide real time visibility into what is going
              on.
            </p>

            {/* Sign In Card */}
            <div className="bg-light text-center py-4 border">
              <h5>Sign In to Built</h5>
              <a
                id="built-sign-in-cta"
                href="https://id.getbuilt.com/"
                rel="noopener noreferrer"
                target="_blank"
                className="btn btn-success"
              >
                Sign In
              </a>
            </div>

            {/* Built Resources Card */}
            <div className="mt-4 border">
              <div className="text-center py-4 bg-light">
                <img src={BuiltLogo} alt="Built Logo" />
              </div>
              <div className="p-3 border-top">
                <h3>Training Resources for Builders and Borrowers</h3>
                <ul className="list-unstyled mb-0">
                  <li>
                    <a
                      id="borrower-welcome-guide-pdf"
                      href="/documents/wafd-bank-borrrower-welcome-guide.pdf"
                      target="_blank"
                    >
                      <Icon lib="fas" name="file-pdf" class="fa-sm mr-2" />
                      Borrower Welcome Guide
                    </a>{" "}
                    <span className="text-secondary">(PDF)</span>
                  </li>
                  <li>
                    <a
                      id="builder-welcome-guide-pdf"
                      href="/documents/wafd-bank-builder-welcome-guide.pdf"
                      target="_blank"
                    >
                      <Icon lib="fas" name="file-pdf" class="fa-sm mr-2" />
                      Builder Welcome Guide
                    </a>{" "}
                    <span className="text-secondary">(PDF)</span>
                  </li>
                  <li className="mb-0">
                    <a
                      href="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleShowBuiltVideo(e);
                      }}
                    >
                      <Icon lib="fas" name="video" class="fa-sm mr-2" />
                      Navigating Built
                    </a>{" "}
                    <span className="text-secondary">(Video)</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-lg-6 mt-5 mt-md-0">
            {/* Built Construction Step by Step */}
            {builtConstuctionSteps.steps.map((step, index) => (
              <div id={`${"step-" + index}`} key={index} className="d-flex flex-column flex-md-row mb-5">
                <div className="mr-5 d-none d-md-block col-auto">
                  <img src={step.iconSrc} alt={step.iconAltText} style={{ width: "128px" }} />
                </div>
                <div className="d-flex d-md-none mx-auto mb-3">
                  <img src={step.iconSrc} alt={step.iconAltText} style={{ width: "96px" }} />
                </div>
                <div className="text-center">
                  <h5 className="text-green-60">{step.text}</h5>
                  <div>
                    <GatsbyImage style={{ maxWidth: "350px" }} image={step.imageSrc} alt={step.imageAltText} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};

export default BuiltConstructionLoanManagement;
